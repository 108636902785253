<template lang="pug">
.container-fluid
  .campaign-flow-top
    om-logo-svg.om-logo(@click.native="navigateToDashboard")
  .campaign-flow-content.d-flex.justify-content-center(:class="contentClassObject")
    .top-left(v-if="enableBack")
      om-link(primary withIconLeft @click="$emit('back')") {{ $t('back') }}
    slot
</template>
<script>
  export default {
    props: {
      enableBack: { type: Boolean, default: true },
      vertical: { type: Boolean, default: false },
    },
    computed: {
      contentClassObject() {
        return { 'flex-column': this.vertical, 'align-items-center': this.vertical };
      },
    },
    methods: {
      navigateToDashboard() {
        this.$router.replace({ name: 'dashboard' });
      },
    },
  };
</script>
<style lang="sass">
  .campaign-flow
    &-top
      padding: 1.25rem 2rem 1.75rem
    &-content
      position: relative
      padding: 0 2rem
      .top-left
        position: absolute
        top: 0
        left: 2rem
  .campaign-flow-top
    .om-logo
      cursor: pointer
</style>
